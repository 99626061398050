<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          label="Cheque No(*)"
          placeholder="Cheque No"
          color="#002441"
          v-model="payload.cheque_no"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Cheque Bank(*)"
          placeholder="Cheque Bank"
          color="#002441"
          v-model="payload.cheque_bank_name"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="payload.cheque_issue_date"
              label="Deposited Date(*)"
              color="#002441"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="payload.cheque_issue_date"
            @input="menu2 = false"
            class="ma-0"
            color="#002441"
            prev-icon="mdi-skip-previous"
            next-icon="mdi-skip-next"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-select
          :items="transaction_to_accounts"
          v-model="payload.transaction_to"
          item-text="account_no"
          item-value="id"
          label="Deposited in A/C(*)"
          placeholder="Deposited in A/C"
          color="#002441"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          label="Reference(*)"
          v-model="payload.reference_number"
          placeholder="Reference number"
          color="#002441"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Amount(*)"
          v-model.number="payload.amount"
          placeholder="0"
          type="number"
          color="#002441"
        ></v-text-field>
      </v-col>
      <v-col>
        <div class="fileUpload">
          <span class="custom-para">Select Image</span>
          <input
            type="file"
            class="upload"
            ref="fileFromCheque"
            v-on:change="handleFileUpload"
          />
        </div>
        <div class="pa-2" v-if="file_name">{{ file_name }}</div>
        <div class="pa-2" style="color: red" v-if="error">{{ error }}</div>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="#002441" elevation="0" @click="onSubmit"> Submit </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["deposite_type", "transaction_to_accounts"],
  data() {
    return {
      seller_id: '',
      payload: {
        cheque_issue_date: new Date().toISOString().substr(0, 10),
      },
      menu2: false,
      file_name: "",
      error: "",
    };
  },
  methods: {
    // Validate file extension
    validateFile(file) {
      if (/\.(jpe?g|png|gif)$/i.test(file.name) === false) return false;
      return true;
    },

    // Handle Image upload
    handleFileUpload() {
      // Validate file if it is an image
      let is_valid_file = this.validateFile(this.$refs.fileFromCheque.files[0]);

      if (is_valid_file) {
        this.payload.image = this.$refs.fileFromCheque.files[0];
        this.file_name = this.payload.image.name;
        this.error = "";
      } else {
        this.payload.image = "";
        this.file_name = "";
        this.error = "Please,upload an image.";
      }
    },

    // Submit the form
    onSubmit() {
      this.payload.seller_id = this.seller_id;
      this.$emit("onSubmitForm", this.payload);

      this.payload = {
        cheque_issue_date: new Date().toISOString().substr(0, 10),
      };

      this.file_name = "";
      this.error = "";
    },
  },
   created(){
    let user = JSON.parse(this.$store.state.user);
    this.seller_id = user.user_id;
  }
};
</script>

<style>
</style>